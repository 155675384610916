import React from "react";
import { useState } from "react";
import styled from "styled-components";
import About from "../components/about/About";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import ModalDownload from "../components/modalDownload/ModalDownload";
import Plataform from "../components/plataform/Plataform";
import ModalSupport from "../components/modalSupport/ModalSupport";

export default function HomePage() {
  const [download, setDownload] = useState(false);
  const [support, setSupport] = useState(false);

  return (
    <ContainerHomePage>
      {support ? (
        <ModalSupport setSupport={setSupport}/>
      ) : (
        <>
          <ModalDownload download={download} setDownload={setDownload} />
          <Header
            setDownload={setDownload}
            setSupport={setSupport}
          />
          <About setDownload={setDownload} />
          <Plataform />
          <Footer
            setDownload={setDownload}
            setSupport={setSupport}
          />
        </>
      )}
    </ContainerHomePage>
  );
}

const ContainerHomePage = styled.div`
  width: 100%;
  height: 100vh;
`;
