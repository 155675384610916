import styled from "styled-components";
import Phone from "../../assets/images/Phone.png";
import { AiOutlineCloudDownload } from "react-icons/ai";
import WavePng from "../../assets/images/wave.png"

export default function About({ setDownload }) {
  return (
    <AboutContainer name="about">
      
     <img src={WavePng} alt="" className="waves"/>
      <HeaderInfo>
        <h1>Recursos disponiveis no andromeda</h1>
        <span>
          Através do software com apenas um clique você tem todo procedimento de
          extração do <strong>Whatsapp</strong>, Contatos entre outras funções.
        </span>
      </HeaderInfo>
      <DataInfoContainer>
        <div>
          <h1>Relatórios</h1>
          <span>Obtenha relatórios completos de extração</span>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1>Contatos</h1>
          <span>Exporte todos os contatos do investigado.</span>
        </div>
        <div>
          <img
            tittle="Clique para fazer o Download"
            src={Phone}
            alt="Imagem de extração celular."
            onClick={() => setDownload(true)}
          />
        </div>
        <div>
          <h1>Espelhar tela</h1>
          <span>Faça o espelhamento WiFi com apenas um clique.</span>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1>Laudo Técnico</h1>
          <span>
            Crie laudos através de Modelos de acordo com o caso periciado.
          </span>
        </div>
      </DataInfoContainer>
      <ResumeDownload>
        <span>faça o download agora mesmo!</span>
        <button onClick={() => setDownload(true)}>
          <div>
            <AiOutlineCloudDownload />
            <tt>DOWNLOAD</tt>
          </div>
        </button>
      </ResumeDownload>
    </AboutContainer>
  );
}

const HeaderInfo = styled.div`
  @media (max-width: 1366px) {
    h1 {
      font-size: 2.2rem !important;
    }
    span {
      font-size: 1rem !important;
    }
  }

  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3rem;
  }
  span {
    margin-top: 10px;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 1.2rem;
  }
  strong {
    font-weight: 600;
  }
`;
const DataInfoContainer = styled.div`
  @media (max-width: 1366px) {
    h1 {
      font-size: 1.5rem !important;
    }
    span {
      font-size: 1rem !important;
    }
  }
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 25%;
    height: 100%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 2rem;
      font-family: "Montserrat";
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      text-align: left;
    }
    span {
      font-family: "Montserrat";
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
    }

    &:nth-child(1) {
      padding: 5%;
      align-items: flex-start;

      span {
        text-align: left;
      }
    }
    &:nth-child(2) {
      //Div da foto do celular.
      width: 35%;
      img {
        animation: 3s 2smartphone infinite ease-in;

        &:hover {
          cursor: pointer;
        }
      }
      @keyframes smartphone {
        0% {
          width: 80%;
        }
        50% {
          width: 100%;
        }
        100% {
          width: 80%;
        }
      }
    }
    &:nth-child(3) {
      padding: 4%;
      align-items: flex-end;

      span {
        text-align: right;
      }
    }

    img {
      margin-left: 10%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: drop-shadow(10px 10px 50px rgba(0, 0, 0, 0.5));
    }
  }
`;
const ResumeDownload = styled.div`
  z-index: 1;
  @media (max-width: 1366px) {
    button {
      height: 55px !important;
      font-size: 1.2rem !important;
      letter-spacing: 0px !important;
    }
    span {
      margin-bottom: 0px !important;
      font-size: 0.9rem !important;
      margin-top: -10px;
    }
  }
  width: 100%;
  height: 15%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  span {
    font-family: "Montserrat";
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: -10px;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));

    transition: 0.1s linear;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    tt {
      margin-left: 20px;
    }
  }
  button {
    width: 20%;
    height: 70px;
    margin-top: 5px;
    transition: 0.3s all;
    border-radius: 10px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    font-size: 1.8rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.2rem !important;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px rgba(39, 54, 76, 0.3);
      font-weight: 600;
    }
  }
`;
const AboutContainer = styled.div`
@media (max-width: 1366px){
  padding: 1%;
}
  z-index: 2;
  background: rgb(240, 243, 248);
  background: linear-gradient(
    180deg,
    rgba(240, 243, 248, 1) 0%,
    rgba(184, 198, 219, 1) 35%,
    rgba(184, 198, 219, 1) 50%,
    rgba(240, 243, 248, 1) 100%
  );
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 2%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .waves {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    rotate: 180deg;
    width: 100%;
  }
`;
