import styled from "styled-components";
import Apresentation from "../assets/images/LHS.png";
import Logo from "../assets/images/logo.png";
import { CgArrowLeftO } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();
  function registerData(event) {
    event.preventDefault();

    window.alert("Cadastro em desenvolvimento, por favor tente mais tarde.");
  }
  return (
    <HeaderContainer>
      <div className="LogoContainer">
        <img src={Apresentation} alt="Imagem Andromeda" />
        <tt>Extrator de Dados Forense</tt>
      </div>
      <LoginMenu>
        <img src={Logo} alt="Logo da Empresa" />
        <h1>Efetue o cadastro agora:</h1>

        <form onSubmit={(event) => registerData(event)}>
          <input type="email" placeholder="Digite o seu E-mail" />
          <input type="text" placeholder="Digite o seu nome" />
          <input type="password" placeholder="Digite a sua Senha" />
          <input type="password" placeholder="Confirme a sua Senha" />
          <button>Cadastrar</button>
        </form>

        <h4 onClick={() => navigate("/login")}>
          Tenho uma conta, logar agora.
        </h4>
      </LoginMenu>

      <ArrowAnimated onClick={() => navigate("/")}>
        <CgArrowLeftO />
        <h6>Voltar</h6>
      </ArrowAnimated>
    </HeaderContainer>
  );
}
const LoginMenu = styled.div`
  img {
    height: 200px !important;
    margin-bottom: 10px !important;
    object-fit: cover !important;
  }
  width: 25%;
  min-width: 400px;
  height: 100vh;
  background: rgb(240, 243, 248);
  background: linear-gradient(
    180deg,
    rgba(184, 198, 219, 1) 0%,
    rgba(240, 243, 248, 1) 100%
  );
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  right: 50px;
  position: absolute;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: -10px;
    margin-bottom: 10px;
    color: rgba(39, 54, 76, 0.3);
  }

  span,
  h4 {
    font-family: "Montserrat";
    font-weight: 400;

    transition: 0.1s linear;
    &:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  form {
    font-family: "Montserrat";

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      border-radius: 6px;
      width: 70%;
      height: 50px;
      padding: 6px;
      margin-bottom: 20px;
      color: #000000;
      background-color: transparent;
      border: 1px solid black;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-weight: 600;
      font-family: "Montserrat";

      transition: all 0.6s;
      &:hover {
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
    input {
      border-radius: 6px;
      width: 70%;
      height: 50px;
      padding: 2%;
      border: 1px solid darkgray;
      margin-bottom: 10px;

      transition: all 0.3s;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
const HeaderContainer = styled.div`
  .LogoContainer {
    width: 100%;
    margin-right: 400px;
  }
  h5 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Montserrat";
    letter-spacing: 1.6px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      font-weight: 600;
    }
  }
  background: rgb(240, 243, 248);
  background: linear-gradient(
    0deg,
    rgba(240, 243, 248, 1) 0%,
    rgba(184, 198, 219, 1) 50%,
    rgba(240, 243, 248, 1) 100%
  );
  width: 100%;
  height: 100vh;

  position: relative;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 48px;
  }
  img {
    width: 70%;
    height: 265px;
    object-fit: contain;
    transition: 0.7s ease-in-out !important;
    margin-bottom: -50px;

    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  }
  tt {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    font-size: 1rem;
    margin-top: 0px;
    letter-spacing: 0.6rem;

    :hover {
      cursor: default;
    }
  }
`;

const ArrowAnimated = styled.div`
  position: absolute;
  top: 30px;
  left: 60px;
  transform: translateX(-50%);
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;

  &:hover {
    animation: 1s homeback infinite ease-out;
    cursor: pointer;
    h6 {
      font-weight: 600;
    }
  }
  h6 {
    margin-top: 10px;
    font-size: 12px;
    font-family: "Montserrat";
    text-transform: uppercase;
  }

  @keyframes homeback {
    0% {
      left: 60px;
    }
    50% {
      left: 45px;
    }
    100% {
      left: 60px;
    }
  }
`;
