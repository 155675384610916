import React from "react";
import styled from "styled-components";
import DashContent from "../components/dashboard/DashContent";
import SideMenu from "../components/dashboard/SideMenu";

export default function Dashboard() {
  return (
    <DashboardContainer>
      <SideMenu />
      <DashContent />
    </DashboardContainer>
  );
}

const DashboardContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
