import styled from "styled-components";
import Logo from "../../assets/images/logo-asset.png";
import { AiFillInstagram, AiOutlineWhatsApp, AiTwotoneMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

export default function Footer({setDownload, setSupport}) {
  const navigate = useNavigate();
  
  return (
    <FooterContainer>
      <ContainerAll>
        <ContentA>
          <img src={Logo} alt="Logo da empresa" />
          <h3>LHS SISTEMAS</h3>
          <ul>
            <li>
            <Link to="about" smooth="true" duration={500} spy={true}><h3>SAIBA MAIS</h3></Link>
            </li>
            <li onClick={() => {setDownload(true)}}>
              <h3>DOWNLOAD</h3>
            </li>
            <li onClick={() => {setSupport(true)}}>
              <h3>SUPORTE</h3>
            </li>
            <li onClick={() => navigate("/login")}>
              <h3>LOGIN</h3>
            </li>
          </ul>
        </ContentA>
        <ContentA>
          <h3>SOCIAL</h3>
          <br />
          <SocialIcons>
            <div>
              <AiOutlineWhatsApp />
            </div>
            <div>
              <AiTwotoneMail />
            </div>
            <div>
              <AiFillInstagram />
            </div>
          </SocialIcons>
        </ContentA>

        <span>
          TODOS OS DIREITOS RESERVADOS |
          <strong> © LHS-SISTEMAS CONSULTORIAS LTDA </strong>
        </span>
      </ContainerAll>
    </FooterContainer>
  );
}
const ContainerAll = styled.div`

  display: flex;
  width: 100%;
  height: 200px;
  padding: 5%;
`;
const SocialIcons = styled.div`
  width: 60%;
  height: 70px;
  padding: 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  div {
    width: 60px;
    height: 60px;
    font-size: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background-color: #ffffff;
    transition: 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      width: 62px;
      height: 62px;
      box-shadow: 0px 0px 40px  rgba(255, 0, 0, 0.8);
    }
  }
`;

const ContentA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 80%;

  img {
    object-fit: cover;
    width: 100px;
  }
  h3 {
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 600;
    margin-top: 0px;
  }
  ul {
    width: 35%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: #fff;

    li {
      h3 {
        font-family: "Montserrat", sans-serif;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 14px;

        margin-right: 30px;

        transition: 0.3s ease-in-out;

        :hover {
          font-weight: 600;
          letter-spacing: 1.4px;
          color: #ff0000;
          cursor: pointer;
        }
      }
    }
  }
`;
const FooterContainer = styled.div`
  background-color: #000000;

  width: 100%;
  height: 220px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  span {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    position: absolute;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 12px;
    color: #fff;
  }
  strong {
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.4px;

    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      letter-spacing: 0.5px;
      font-weight: 720;
    }
  }
`;
