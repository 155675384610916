import styled from "styled-components";
import Mockup from "../../assets/images/MockupWeb.png";
import { BsApple, BsGoogle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Plataform() {
  const navigate = useNavigate();
  return (
    <PlataformContainer>
      <ContentA>
        <img src={Mockup} alt="Computador na tela de relatorios na nuvem" />
      </ContentA>
      <ContentB>
        <HeaderContent>
          <h1>ANDROMEDA WEB</h1>
          <span>
            Tenha acesso a todos os relatórios periciais de onde estiver
            acessando a plataforma na nuvem.
          </span>
        </HeaderContent>
        <ContainerLogin>
          <button onClick={() => navigate("/login")}>
            <h3>Fazer login agora</h3>
          </button>
          <span>Ou cadastre-se atráves:</span>
          <div>
            <BsGoogle />
            <BsApple />
          </div>
        </ContainerLogin>
      </ContentB>
      <tt>
        Protocolo ssl de comunicação segura - Todos os dados estão protegidos de
        acordo com a <strong>Lei nº 13.709/2018 - LGPD</strong>
      </tt>
    </PlataformContainer>
  );
}
const ContainerLogin = styled.div`
  @media (min-width: 1366px) {
    margin-top: 50px;
  }
  width: 95%;
  height: 60%;
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  span {
    font-family: "Montserrat";
    color: #000;
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 30px;
  }
  div {
    width: 40%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    font-size: 3rem;

    &:hover {
      cursor: pointer;
    }
  }

  button {
    width: 60%;
    height: 80px;
    border-radius: 10px;
    border: 2px solid black;
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  h3 {
    font-family: "Montserrat";
    text-transform: uppercase;
    color: #000;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 0.4rem;
  }
`;
const HeaderContent = styled.div`
@media (max-width:1366px){
  margin-top: 100px !important;
 }
  padding: 3%;
  width: 95%;
  height: 20%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    font-family: "Montserrat";
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 0.4rem;
    text-align: center;
    margin-bottom: 20px;
  }
  span {
    font-family: "Montserrat";
    font-size: 1.2rem;
    text-align: center;
    font-weight: 300;
  }
`;
const ContentA = styled.div`
  width: 50%;
  height: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;
const ContentB = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PlataformContainer = styled.div`
  @media (max-width: 1366px) {
    h1 {
      font-size: 2.7rem !important;
    }
  }
  z-index: 2;
  background: rgb(240, 243, 248);
  background: linear-gradient(
    0deg,
    rgba(184, 198, 219, 1) 0%,
    rgba(240, 243, 248, 1) 100%
  );
  width: 100%;
  height: 100vh;
  padding: 6%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  tt {
    position: absolute;
    bottom: 30px;
    font-family: "Montserrat";
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 300;
  }
  strong {
    font-weight: 600;
  }
`;
