import styled from "styled-components";
import Logo from "../../assets/images/logo-asset.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

export default function NavMenu({setDownload, setSupport}) {
  const navigate = useNavigate();
  return (
    <NavBar>
      <MainMenu>
        <div>
          <img src={Logo} alt="Logo da empresa" className="LogoAsset" />
          <Link to="about" smooth="true" duration={500} spy={true}><span>SAIBA MAIS</span></Link>
          <span onClick={() => {setDownload(true)}}>DOWNLOAD</span>
          <span onClick={() => {setSupport(true)}}>SUPORTE</span>
          <span onClick={() => navigate("/login")}>LOGIN</span>
        </div>
      </MainMenu>
    </NavBar>
  );
}

const MainMenu = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 10%;
  left: 0;
  display: flex;
  background-color: rgb(240, 243, 248, 0.9);
  border: 1px solid rgba(184, 198, 219, 0.5);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  z-index: 1;

  justify-content: space-around;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  color: #000;

  .LogoAsset {
    object-fit: contain !important;
    width: 80px !important;
  }

  div {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: rgba(0,0,0,0.6);
      margin-right: 16px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;

      transition: 0.5s all;

      &:hover {
        color: #ff0000;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 1.6px;
      }
    }
  }
`;
const NavBar = styled.div`
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 48px;
  }
  tt {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 20px;
    margin-top: 0px;
    letter-spacing: 32px;

    :hover {
      cursor: default;
    }
  }
`;
