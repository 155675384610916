import styled from "styled-components";
import Apresentation from "../../assets/images/MacHeader.png";
import Andromeda from "../../assets/images/LHS.png";
import { Link } from "react-scroll";
import { BiArrowToBottom } from "react-icons/bi";
import NavBar from "./NavMenu.js";

export default function Header({download, setDownload, setSupport}) {
  return (
    <HeaderContainer>
      <NavBar setDownload={setDownload} setSupport={setSupport}/>

      <InternContentA>
        <img src={Andromeda} alt="Andromeda Concept" />
        <span>
          "A ferramenta forense que você precisa para turbinar a sua produtivida
          na produção de laudos técnicos."
        </span>
        <div>
          <button> <Link to="about" smooth="true" duration={500} spy={true}>Saiba mais</Link></button>
          <button onClick={() => setDownload(true)}>Download</button>
        </div>
      </InternContentA>
      <InternContentB>
        <img src={Apresentation} alt="Computador com o programa aberto" />
      </InternContentB>

      <ArrowAnimated>
        <BiArrowToBottom />
      </ArrowAnimated>
    </HeaderContainer>
  );
}
const InternContentB = styled.div`
  position: relative;
  z-index: 0;
  left: 0;
  top: 0;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;

  img {
    position: absolute;
    width: 90%;
    object-fit: cover;
    animation: 4s macbook infinite ease-in-out;
  }

  @keyframes macbook {
    0% {
      bottom: -15px;
    }
    50% {
      bottom: -30px;
    }
    100% {
      bottom: -15px;
    }
  }
`;
const InternContentA = styled.div`
  z-index: 0;
  left: 0;
  top: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;

  span {
    margin-top: -10px;
    font-family: "Montserrat";
    font-size: 1.4rem;
    font-weight: 300;
    text-align: center;
  }

  div {
    button {
      margin-top: 20px;
      margin-right: 20px;
      font-size: 16px;
      &:first-child {
        width: 200px;
        height: 50px;
        border-radius: 5px;
        border: thin solid black;
        background-color: black;
        color: #ffffff;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: 0.3s all;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          font-weight: 400;
        }
      }

      &:last-child {
        width: 200px;
        height: 50px;
        border-radius: 5px;
        border: thin solid #fafafa;
        background-color: #fafafa;
        color: #000000;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: 0.3s all;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          font-weight: 400;
        }
      }
    }
  }

  @media(max-width:1366px){
    span{
      font-size: 1.2rem;
    }
    img{
      width: 100%;
      object-fit: contain !important;
    }
  }
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin-bottom: -20px;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
  }
`;

const HeaderContainer = styled.div`
  @media (max-width: 1366px) {
    padding: 6%;
    tt {
      font-size: 16px !important;
      letter-spacing: 24px !important;
    }
  }
  background: rgb(240, 243, 248);
  background: linear-gradient(
    0deg,
    rgba(240, 243, 248, 1) 0%,
    rgba(184, 198, 219, 1) 50%,
    rgba(240, 243, 248, 1) 100%
  );
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 7%;

  display: flex;

  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 48px;
  }
  tt {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 20px;
    margin-top: 0px;
    letter-spacing: 32px;

    :hover {
      cursor: default;
    }
  }
`;

const ArrowAnimated = styled.div`

  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;


  font-size: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  animation: 1.3s arrow infinite ease-in-out;

  @keyframes arrow {
    0% {
      bottom: 20px;
    }
    50% {
      bottom: 50px;
      opacity: 0;
    }
    100% {
      bottom: 20px;
    }
  }
`;
