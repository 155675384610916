import React from "react";
import styled from "styled-components";
import {
  GrSettingsOption,
  GrMoney,
  GrAppsRounded,
  GrBook,
  GrPowerShutdown,
} from "react-icons/gr";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <MenuContainer>
      <HeaderData>
        <img
          src="https://365psd.com/images/istock/previews/1009/100996291-male-avatar-profile-picture-vector.jpg"
          alt="Profile Logo"
        />
        <h3>Pedro Henrique S. Silva</h3>
        <span>Fotografo Pericial</span>
      </HeaderData>

      <MenuList>
        <div>
          <GrAppsRounded className="icone" />
          <h4>Inicio</h4>
        </div>

        <div>
          <GrBook className="icone" />
          <h4>Laudos</h4>
        </div>

        <div>
          <GrMoney className="icone" />
          <h4>Licença</h4>
        </div>

        <div>
          <GrSettingsOption className="icone" />
          <h4>Ajustes</h4>
        </div>

        <div onClick={() => navigate("/")}>
          <GrPowerShutdown className="icone" />
          <h4>Sair</h4>
        </div>
      </MenuList>
    </MenuContainer>
  );
}
const MenuList = styled.div`
  @media (max-width: 1366px) {
    height: 70%;
    position: relative !important;;
    div {
      margin-bottom: 20px!important;
      &:last-child {
        position: fixed !important;;
        bottom: -10px !important;
        left: 20px;
        width: 192px;
      }
    }
  }
  width: 100%;
  height: 80%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icone {
    font-size: 28px;
    margin-left: 10px;
  }
  div {
    transition: 0.3s all;

    border-radius: 10px;
    padding: 8px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    img {
      background-color: red;
    }
    display: flex;
    h4 {
      font-family: "Poppins";
      font-weight: 200;
      font-size: 16px;
      margin-left: 20px;
      letter-spacing: 0.5px;

      transition: 0.3s all;
    }
    margin-bottom: 40px;

    /* ULTIMO BOTÃO -> SAIR */
    &:last-child {
      position: absolute;
      bottom: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: #edeff3;
      h4 {
        font-weight: 500;
      }
    }
  }

  span {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 12px;
    text-align: center;
  }
`;

const HeaderData = styled.div`
  @media (max-width: 1366px) {
    h3 {
      font-size: 1rem !important;
    }
  }
  width: 100%;
  height: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 10%;

  img {
    object-fit: cover;
    width: 60%;
    margin-bottom: 10px;
  }
  h3 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
  }
  span {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 12px;
  }
`;

const MenuContainer = styled.div`
  @media (max-width: 1366px) {
    width: 20%;
  }
  width: 15%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
`;
