import styled from "styled-components";
import { AiFillWindows, AiFillApple, AiFillCloseCircle } from "react-icons/ai";
import Andromeda from "../../downloads/andromeda.exe";
import { useState } from "react";

export default function ModalDownload({ download, setDownload }) {
  const [downloading, setDownloading] = useState(false);
  function baixarWindows() {
    setDownloading(true);
    setTimeout(() => {
      setDownloading(false);
      setDownload(false);
    }, 3000);
  }
  function baixarMac() {
    window.alert(
      "Aguarde, em breve a versão para Mac estará disponivel para download..."
    );
  }
  function downloadError() {
    if (
      window.confirm(
        "Deseja entrar em contato com nosso Suporte através do WhatsApp?"
      )
    ) {
      window.open(
        "https://api.whatsapp.com/send?phone=5518996000012&text=Estou%20com%20problemas%20na%20hora%20de%20baixar%20o%20programa%20atrav%C3%A9s%20do%20site%20do%20Andromeda,%20podem%20me%20ajudar?",
        "_blank"
      );
    }
  }
  return (
    <>
      {download === false ? (
        <></>
      ) : (
        <ModalContainer>
          {downloading ? (
            <div className="LoadingSpinner">
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <h3>Aguarde, este processo pode demorar alguns instantes</h3><br/>
              <h3>O download iniciará automaticamente.</h3>
            </div>
          ) : (
            <h1>Selecione o seu sistema operacional:</h1>
          )}
          <div>
            <button onClick={() => baixarWindows()}>
              <a href={Andromeda} download>
                <div>
                  <AiFillWindows />
                  <span>Windows</span>
                </div>
              </a>
            </button>
            <button onClick={() => baixarMac()}>
              <div>
                <AiFillApple />
                <span>Mac OS</span>
              </div>
            </button>
          </div>
          <tt onClick={() => downloadError()}>
            Notificar um problema com o download!
          </tt>
          <CloseModal onClick={() => setDownload(false)}>
            <AiFillCloseCircle />
          </CloseModal>
        </ModalContainer>
      )}
    </>
  );
}
const CloseModal = styled.div`
  width: 80px;
  height: 80px;
  position: fixed;
  right: 20px;
  top: 10px;

  font-size: 3rem;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 20px rgba(255, 0, 0, 0.9));
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 3 !important;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(236, 236, 236, 0.7);
  backdrop-filter: blur(2px);
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    color: #fff;
    text-decoration: none;
  }
  h3 {
    font-size: 1.2rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 400;
  }
  tt {
    font-size: 1rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 400;
    position: absolute;
    bottom: 30px;
    transition: 0.3s ease-in;

    &:hover {
      cursor: pointer;
      font-weight: 600;
    }
  }
  h1 {
    font-size: 1.6rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.2rem;
  }
  span {
    margin-left: 10px;
    font-size: 1.8rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 600;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    width: 400px;
    height: 70px;
    margin-right: 40px;
    margin-top: 20px;
    font-size: 1.6rem;
    transition: 0.3s all;

    &:first-child {
      border-radius: 10px;
      background-color: #000000;
      color: #ffffff;
    }
    &:last-child {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid #000000;
      color: #000000;
    }
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  .LoadingSpinner {
    display: flex;
    flex-direction: column;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
