import React from "react";
import styled from "styled-components";

export default function DashContent() {
  return (
    <ContainerDash>
      <InfoContainer>
        <tt>Nenhum laudo para mostrar no momento.</tt>
      </InfoContainer>
    </ContainerDash>
  );
}
const InfoContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  tt{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.3px;
  }
`;

const ContainerDash = styled.div`
  width: 100%;
  height: 100vh;
  padding: 30px;

  background-color: #edeff3;
`;
